.regional-assessment :where(.css-dev-only-do-not-override-1hpnbz2).ant-layout-sider .ant-layout-sider-trigger {
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 48px;
    color: #fff;
    line-height: 48px;
    text-align: center;
    background: #008D6B; /* Changed from #002140 to green */
    cursor: pointer;
    transition: all 0.2s;
    border-radius: 0px 0px 20px 0px;
    height: 60px;
}


.regional-assessment .sticky-sidebar {
    position: sticky;  /* Stick the sidebar when scrolling */
    top: 0;            /* Keep it at the top of the viewport */
    height: 100vh;      /* Make sure it takes up full height */
    background: #008D6B;  /* Same background as before */
    z-index: 1000;      /* Ensure it's above other content */
    border-radius: 0px 20px 20px 0px;
  }

  .regional-assessment :where(.css-dev-only-do-not-override-xex5fb).ant-layout-sider .ant-layout-sider-trigger {
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 48px;
    color: #fff;
    line-height: 48px;
    text-align: center;
    background: #008D6B; 
    cursor: pointer;
    transition: all 0.2s;
}