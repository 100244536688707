:root {
  --base-font-size: 2vw !important;
  --small-font-size: calc(var(--base-font-size) * 0.875);
  --large-font-size: calc(var(--base-font-size) * 1);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.logo {
  height: 32px;
  margin: 16px;
}

.left-navbar {
  display: flex;
  align-items: center;
}

.right-navbar {
  display: flex;
  align-items: center;
}

.home_btns {
    font-style: normal;
    font-weight: 700 !important;
    font-size: var(--large-font-size) !important
}

button .ant-btn-icon {
  padding-right: 20px;
}

.wizard_steps_col {
  display: flex;
  justify-content: center;
}

.level_btn:hover, .level_btn:active, .active:active {
  background: #00678A !important;
}

.ant-spin-nested-loading {
  background-color: #FFF;
}

.ant-spin-dot {
  font-size: 70px !important;
}

.ant-spin-dot-item {
  width: 30px !important;
  height: 30px !important;
  opacity: 1 !important;
  color: #00678A
}

.ant-spin-text {
  margin-left: 30px;
  top: 65% !important;
  font-size: 22px !important;
  color: #00678A
}

.ant-spin-nested-loading .ant-spin-blur {
  opacity: 0.2 !important;
}

.ant-slider .ant-slider-handle, 
.ant-slider .ant-slider-handle::before, 
.ant-slider .ant-slider-handle:hover::before, 
.ant-slider .ant-slider-handle:focus::before,
.ant-slider-handle::after,
.ant-slider .ant-slider-handle:hover::after,
.ant-slider .ant-slider-handle:focus::after  {
  width: 34px !important;
  height: 34px !important;
  inset-block-start: inherit;
}

.ant-slider .ant-slider-handle::after, .ant-slider .ant-slider-handle:hover::after, .ant-slider .ant-slider-handle:active::after, .ant-slider .ant-slider-handle:focus::after {
  outline: none;
}

.ant-slider-rail, .ant-slider-track, .ant-slider-step {
  border-radius: 15px !important;
}

.ant-slider .ant-slider-track {
  background: linear-gradient(90deg, rgba(32,116,95,1) 0%, rgba(81,170,147,1) 50%);
  border-radius: 2px;
}

.ant-slider-horizontal .ant-slider-mark {
  top: 70px;
}

.ant-slider-horizontal .ant-slider-step {
  display: none;
}

.technology-table-group {
  background-color: #15728F !important;
  color: #FFF !important;
  font-size: 18px;
  text-align: center !important;
}

.technology-table-child {
  font-size: 16px;
  text-align: center !important;
  padding: 6px !important;
}



.splitCellText {
  padding: 8px 10px;
}

.technology_mixes_table .table-row-light {
  background-color: #e9e6e652 !important; 
}

.technology_mixes_table .table-row-dark {
  background-color: #7f76762d !important; 
}

.technology_mixes_table table {
  border-collapse: collapse
}

.technology_mixes_table .bordered {
  border-top: 2px solid #FFF !important
}

.ant-table-tbody > tr:hover {
  cursor: pointer; /* Add pointer cursor */
}

.selectedSectorsCard .ant-card-body {
  padding: 0px;
}

.total-row, .rowTotal {
  font-weight: bold;
}

.totalLabel {
  font-weight: bold;
  color: #FFF;
  background-color: #006187;
}

.ant-col:nth-child(1) .business_card {
  border-left: none;
}

.ant-col:nth-child(5) .business_card, .ant-col:nth-child(2) .business_card {
  border-right: none;
}

.business_card {
  border-bottom: 2px solid #006187;
  border-left: 2px solid #006187;
  border-top: none;
  border-radius: 0px;
  color: #707070;
}

.business_card:hover {
  border-bottom: 2px solid #006187

}

.business_card .ant-card-head {
  color: #006187;
  border-bottom: none
}

.business_card .ant-card-body {
  padding: 0;
  display: flex;
  height: 100%
}

.business_card_public_auth .ant-card-head-title {
  font-weight: bold;
  color: #006187;
}

.business_card_public_auth:nth-child(1) .ant-card-head-title, .business_card_public_auth:nth-child(2) .ant-card-head-title {
  color: #158D6B; /* Set the color to red for the first child */
}

.business_card_public_auth:nth-child(7) .ant-card-head-title, .business_card_public_auth:nth-child(8) .ant-card-head-title {
  color: #259392; /* Set the color to red for the first child */
}

.business_card_public_auth .ant-card-head {
  text-align: center;
  border-bottom: none
}

.business_card_public_auth .ant-card-body {
  padding: 0;
  height: 100%;
  color: #707070;
}

.environmental-table-group {
  background-color: #15728F !important;
  color: #FFF !important;
  font-size: 14px;
  text-align: center !important;
}

@media (max-width: 1199px) {
.ant-col:nth-child(5) .business_card {
  border-right: 2px solid #006187;
}
}


@media (min-width: 992px) {
  .wizard_steps_buttons {
    width: 65%
  }

  .wizard_steps_buttons .ant-list {
    padding: 10px 100px;
  }

  .level_btn {
    width: 50%
  }
  
  .titleFormCol {
    width: 50%
  }

  .wizard_steps_buttons .titleFormCol {
      width: 70%;
  }

  .downloadBtn {
    margin: auto -100px;
  }
}

@media (max-width: 991px) {
  .wizard_steps_buttons {
    width: 100%
  }

  .wizard_steps_buttons .ant-list {
    padding: 10px;
  }

  .level_btn {
    width: 100%
  }

  .titleFormCol {
    width: 100%
  }
}


.backgroundTermsIndex{
  background-image: url("../public/images/background.png");
  min-height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;
  background-position: center center;
}

.logoTermsIndex{
  width: 20%;  
}

.startButtonTermsIndex{
  font-size: 20px;
  font-family: Tahoma, Verdana, sans-serif;  
  padding: 20px 40px;
}

/* hide top logo */
@media (min-width: 604px) {
  .backbuttonMargin{
    margin-left: 70px;
  }
}

@media (min-width: 603px) {
  .logoHiden{
    display: none !important;
  }

  .layoutPaddingTop{
    padding-top: 60px !important;   
  }
}

/* hide left side logo */
@media (max-width: 603px) {
  .logoLeftSideHiden{
    display: none !important;
  }
}

@media (min-width: 576px) {
  .title_results_col {
    max-width: 90%;
    align-items: center;
    justify-content: center;
    display: flex
  }

  .downloadBtn {
    margin: auto;
  }
}

@media (max-width: 575px) {
  .downloadBtn {
    display: flex;
    justify-content: center;
  }
}